.pred-container {
  margin-bottom: 20px;
}

.pred-container > div {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}

.confidence-row {
  margin-bottom: 10px;
}
.score-row {
  display: table-row;
}
.cell {
  display: table-cell;
  padding-right: 10px;
}
.score-label {
  color: #bfbdbd;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  margin-top: 5px;
}
.score-value {
  color: #454545;
  font-size: 28px;
  font-weight: bold;
}

#file-container {
  margin-bottom: 20px;
}

#file-container #files {
  visibility: hidden;
}

.stars-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  font-size: x-large;
}

.stars-outer::before {
  content: "\f006 \f006 \f006 \f006 \f006";
}

.stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.stars-inner::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  color: #f8ce0b;
}